import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// component imports
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Paper } from '@material-ui/core';

import {
  withAITracking,
  useAppInsightsContext,
} from '@microsoft/applicationinsights-react-js';
import DesktopDVRData from './DesktopDVRData';
import MobileDVRData from './MobileDVRData';
import RecordDataFooter from './RecordDataFooter';

// style imports
import '../styles/RecordData-style.css';

// eslint-disable-next-line no-unused-vars
import '../i18n';
// eslint-disable-next-line no-unused-vars
import RecordDataHeader from './RecordDataHeader';

import { reactPlugin } from '../utils/ApplicationInsightsService';

function RecordData({
  apple,
  user,
  record,
  isMobile,
  isVersionGood,
  htmlDir,
  setHtmlDir,
  setShowDVRHeader,
  settings,
  myTurnBannerGroupCodes,
  setMyTurnBannerGroupCodes,
}) {
  // eslint-disable-next-line no-unused-vars
  const { i18n } = useTranslation();
  const [showEvaluatorUnavailableBanner, setShowEvaluatorUnavailableBanner] =
    React.useState(true);
  // eslint-disable-next-line no-unused-vars
  const [isDesktop, setIsDesktop] = React.useState(isMobile() === 'not mobile');

  const appInsights = useAppInsightsContext();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    pageLoaded();
    changeLanguage('en');
  }, []);

  const pageLoaded = () => {
    appInsights.trackEvent({ name: 'DVR record page loaded' });
  };

  const hideDVRHeader = () => setShowDVRHeader(false);

  // evaluator unavailable banner
  const useStyle = makeStyles(() => ({
    container: {
      width: '100%',
    },
    typo: {
      background: 'inherit',
      margin: '2.5vw 20vw',
    },
    success: {
      background: '#FFFACD',
      content: 'Success',
      width: '100vw',
      padding: '1px',
      boxSizing: 'border-box',
      margin: 0,
      border: 'none',
      position: 'relative',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    button: {
      position: 'absolute',
      right: 10,
      margin: 20,
      height: 30,
      width: 30,
      padding: 0,
    },
    img: {
      backgroundColor: '#FFFACD',
    },
  }));

  const handleEvaluatorUnavailableBannerToggle = () => {
    setShowEvaluatorUnavailableBanner((currentState) => !currentState);
  };

  function EvaluatorUnavailableBanner() {
    const classes = useStyle();

    return (
      <Paper
        className={classes.success}
        style={!isDesktop ? { zIndex: '10' } : null}
        square
        elevation={0}
      >
        <button
          className={classes.button}
          type="button"
          onClick={handleEvaluatorUnavailableBannerToggle}
        >
          <img
            className={classes.img}
            src="/imgs/x.svg"
            alt="x for banner"
            // background="#FFFACD"
          />
        </button>
        <Typography
          align="center"
          className={classes.typo}
          style={!isDesktop ? { marginLeft: '13vw' } : null}
        >
          <strong>Note: </strong>Vaccine recommendations are not available at
          this time.
        </Typography>
      </Paper>
    );
  }

  // get the color of the evaluator output (Red, Green, Yellow, Blue)
  function getEvaluatorStatusColor(props) {
    const dueNow = props == null ? false : props.dueNow;

    // no status, default to not recommended
    let status = props == null ? 'NOT_RECOMMENDED' : props.status;
    let statusColor = 'black';

    if (
      props &&
      props.isPatientDiscretion &&
      props.isPatientDiscretion === 'true'
    ) {
      status = 'CLINICAL_PATIENT_DISCRETION';
    }

    switch (status) {
      case 'RECOMMENDED':
      case 'FUTURE_RECOMMENDED':
        statusColor = dueNow ? '#D30F00' : '#FDB81E';
        break;

      case 'CONDITIONAL':
      case 'NOT_RECOMMENDED':
        statusColor = '#009227';
        break;

      case 'CLINICAL_PATIENT_DISCRETION':
        statusColor = '#A3D8F4';
        break;
      default:
    }
    return statusColor;
  }

  // get the circle width for DVR evaluator color
  function getCircleStrokeWidth(props) {
    const dueNow = props == null ? false : props.dueNow;

    // no status, default to not recommended
    let status = props == null ? 'NOT_RECOMMENDED' : props.status;
    let strokeWidth = 0;

    if (
      props &&
      props.isPatientDiscretion &&
      props.isPatientDiscretion === 'true'
    ) {
      status = 'CLINICAL_PATIENT_DISCRETION';
    }

    switch (status) {
      case 'RECOMMENDED':
      case 'FUTURE_RECOMMENDED':
        strokeWidth = dueNow ? '0' : '1.5';
        break;

      case 'CLINICAL_PATIENT_DISCRETION':
        strokeWidth = '1.5';
        break;
      default:
    }

    return strokeWidth;
  }

  // display the evaluator status + date if included
  function displayEvaluatorStatus(props) {
    // if due now, display overdueDate. Otherwise show recommended date
    const date =
      props == null
        ? null
        : props.dueNow === true
          ? props.overdueDate
          : props.recommendedDate;

    const dueNow = props == null ? false : props.dueNow;
    // no status, default to not recommended
    const status = props == null ? 'NOT_RECOMMENDED' : props.status;
    let displayStatus = status;

    switch (status) {
      case 'RECOMMENDED':
      case 'FUTURE_RECOMMENDED':
        displayStatus = dueNow ? `Overdue ${date} ` : `Next Dose Due ${date} `;
        break;
      case 'CONDITIONAL':
      case 'NOT_RECOMMENDED':
        displayStatus = 'Complete ';
        break;
      case 'RECOMMENDATION_NOT_AVAILABLE':
        displayStatus = 'Recommendation Not Available ';
        break;
      default:
    }

    return displayStatus;
  }

  // display the evaluator status + date if included for mobile
  function displayEvaluatorStatusMobile(props) {
    // if due now, display overdueDate. Otherwise show recommended date
    const date =
      props == null
        ? null
        : props.dueNow === true
          ? props.overdueDate
          : props.recommendedDate;

    const dueNow = props == null ? false : props.dueNow;
    // no status, default to not recommended
    const status = props == null ? 'NOT_RECOMMENDED' : props.status;
    let displayStatus = status;

    switch (status) {
      case 'RECOMMENDED':
      case 'FUTURE_RECOMMENDED':
        displayStatus = dueNow
          ? `Next dose was overdue on: ${date} `
          : `Next Dose Due: ${date} `;
        break;
      case 'CONDITIONAL':
      case 'NOT_RECOMMENDED':
        displayStatus = 'Series Complete';
        break;
      case 'RECOMMENDATION_NOT_AVAILABLE':
        displayStatus = 'Recommendation Not Available ';
        break;
      default:
    }

    return displayStatus;
  }

  // display a vaccine group's full name + abbreviation
  function displayVaccineGroupName(group) {
    const groupLabel = group.groupName;
    return groupLabel;
  }

  return (
    <div>
      {!record.evaluatorAvailable && showEvaluatorUnavailableBanner && (
        <EvaluatorUnavailableBanner />
      )}

      {/* record data header */}
      <RecordDataHeader
        isDesktop={isDesktop}
        record={record}
        displayVaccineGroupName={displayVaccineGroupName}
        displayEvaluatorStatus={displayEvaluatorStatus}
        htmlDir={htmlDir}
        setHtmlDir={setHtmlDir}
      />

      <div className="margin-container">
        {/* display DVR data */}
        {isDesktop ? (
          // desktop layout
          <DesktopDVRData
            record={record}
            getCircleStrokeWidth={getCircleStrokeWidth}
            getEvaluatorStatusColor={getEvaluatorStatusColor}
            displayVaccineGroupName={displayVaccineGroupName}
            displayEvaluatorStatus={displayEvaluatorStatus}
            setMyTurnBannerGroupCodes={setMyTurnBannerGroupCodes}
            myTurnBannerGroupCodes={myTurnBannerGroupCodes}
          />
        ) : (
          // mobile layout
          <MobileDVRData
            record={record}
            getEvaluatorStatusColor={getEvaluatorStatusColor}
            displayVaccineGroupName={displayVaccineGroupName}
            displayEvaluatorStatus={displayEvaluatorStatusMobile}
            settings={settings}
          />
        )}

        <RecordDataFooter
          record={record}
          displayVaccineGroupName={displayVaccineGroupName}
          displayEvaluatorStatus={displayEvaluatorStatus}
          isVersionGood={isVersionGood}
          apple={apple}
          isMobile={isMobile}
          user={user}
          settings={settings}
        />
      </div>
    </div>
  );
}

export default withAITracking(reactPlugin, RecordData, 'All Vaccines Record');
